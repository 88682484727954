<template>
  <Layout>
    <template v-slot:content>
      <FollowModal
        v-if="showFollowModal"
        :users="users"
        :type="followType"
        @closeModal="toggleFollowerModal"
      />
      <div class="h-screen">
        <div
          class="container mx-auto max-w-4xl px-6 mt-6 md:mt-6 flex flex-col md:flex-row items-center md:items-start"
        >
          <div
            class="h-36 w-36 rounded-full border md:mr-16 flex items-center justify-center"
          >
            <img
              class="rounded-full"
              :src="`https://d1916o647hwu0n.cloudfront.net/${user.image}`"
              alt="Profile Picture"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex items-start py-4">
              <div class="flex flex-col">
                <h1 class="text-4xl text-gray-800 mr-8">{{ user.username }}</h1>
                <span class="font-semibold text-gray-800 mt-2">{{
                  user.name
                }}</span>
              </div>
              <button
                v-if="!ownProfile && !following"
                class="border rounded text-gray-800 px-3 py-1 text-sm hover:bg-primary hover:text-white"
                @click="followUser"
              >
                {{ $t("follow") }}
              </button>
            </div>
            <div class="flex">
              <span class="mr-3 cursor-pointer" @click="showFollower"
                ><strong>{{
                  user.follower ? user.follower.length : "0"
                }}</strong>
                {{ $t("follower") }}</span
              >
              <span class="cursor-pointer" @click="showFollowing"
                ><strong>{{ user.follows ? user.follows.length : "0" }}</strong>
                {{ $t("follows") }}</span
              >
            </div>
          </div>
        </div>
        <div class="container md:w-2/3 mx-auto mt-6 md:mt-8 border-t py-4">
          <div class="px-4 md:px-0 grid md:grid-cols-2 md:gap-4">
            <div v-for="feed in feeds" :key="feed._id">
              <FeedItem :feed="feed" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import Layout from "../Layouts/Layout.vue";
import FeedItem from "@/components/FeedItem";
import FollowModal from "@/components/FollowModal";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Profile",
  components: { Layout, FeedItem, FollowModal },
  setup() {
    const currentUser = ref(JSON.parse(window.localStorage.getItem("user")));
    const user = ref({});
    const users = ref([]);
    const feeds = ref([]);
    const route = useRoute();
    const router = useRouter();
    const showFollowModal = ref(false);
    const followType = ref(null);

    onMounted(async () => {
      await getUser(route.params.username);
      await getUserFeed(user.value._id);
    });

    watch(
      () => route.params.username,
      async (username) => {
        await getUser(username);
        await getUserFeed(user.value._id);
      }
    );

    const getUser = async (username) => {
      try {
        const { data } = await window.axios.get("/users/" + username);
        user.value = data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const getUserFeed = async (userId) => {
      try {
        const { data } = await window.axios.get("/users/" + userId + "/feed");
        feeds.value = data;
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const followUser = async () => {
      try {
        const response = await window.axios.post("/users/follow", {
          user: user.value._id,
        });

        window.emitter.emit("toggleFlash", {
          message: response.data.message,
          type: response.data.type,
        });
      } catch (error) {
        if (error && error.response.status === 401) {
          window.localStorage.removeItem("token");
          router.push("/");
        } else {
          window.emitter.emit("toggleFlash", {
            message: "error",
            type: "error",
          });
        }
      }
    };

    const toggleFollowerModal = () =>
      (showFollowModal.value = !showFollowModal.value);

    const showFollower = () => {
      toggleFollowerModal();
      users.value = user.value.follower;
      followType.value = "follower";
    };

    const showFollowing = () => {
      toggleFollowerModal();
      users.value = user.value.follows;
      followType.value = "following";
    };

    const getTimeDiff = (date) => window.dayjs(date).fromNow();

    const ownProfile = computed(
      () => currentUser.value.username === route.params.username
    );

    const following = computed(() =>
      currentUser.value.follows.includes(user.value._id)
    );

    return {
      currentUser,
      user,
      users,
      feeds,
      followUser,
      following,
      getTimeDiff,
      ownProfile,
      followType,
      toggleFollowerModal,
      showFollowModal,
      showFollower,
      showFollowing,
    };
  },
};
</script>