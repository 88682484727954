<template>
  <div
    class="fixed top-0 z-50 w-full h-screen bg-black bg-opacity-25"
    aria-hidden="true"
  >
    <div class="relative h-full md:w-2/3" @click="$emit('closeModal')"></div>
    <div
      class="fixed overflow-y-auto top-0 right-0 w-full h-screen p-8 bg-white border-t-4 border-solid z-45 md:w-1/3 border-primary"
    >
      <div>
        <svg
          class="w-6 h-6 cursor-pointer hover:text-red-200"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="$emit('closeModal')"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="mt-10">
        <h1 class="text-4xl text-primary">{{ $t(type) }}</h1>
        <ul class="mt-4">
          <li
            class="flex items-center bg-gray-50 px-4 py-2 cursor-pointer mt-2"
            v-for="user in users"
            :key="user._id"
            @click="goToUserPage(user.username)"
          >
            <div class="border rounded-full">
              <div class="h-9 w-9 rounded-full border">
                <img
                  v-if="user.image"
                  class="rounded-full h-9 w-9"
                  :src="`https://d1916o647hwu0n.cloudfront.net/${user.image}`"
                  alt="Profile Picture"
                />
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <div class="ml-3 flex flex-col">
              <span class="text-sm font-semibold">{{ user.username }}</span>
              <span class="text-sm text-gray-400">{{ user.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "FollowModal",
  props: ["users", "type"],
  emits: ["closeModal"],
  setup(props, { emit }) {
    const router = useRouter();

    const goToUserPage = (username) => {
      router.push(`/${username}`);
      emit("closeModal");
    };

    return {
      goToUserPage,
    };
  },
};
</script>
