<template>
  <div
    class="rounded border flex flex-col mb-6 cursor-pointer bg-white"
    @click="openDiary"
  >
    <div class="flex items-center px-4 py-3">
      <div class="h-9 w-9 rounded-full border mr-3">
        <img
          v-if="feed.user.image"
          class="rounded-full h-9 w-9"
          :src="`https://d1916o647hwu0n.cloudfront.net/${feed.user.image}`"
          alt="Profile Picture"
        />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <h2 class="text-sm font-semibold text-gray-800">
        {{ feed.user.username }}
      </h2>
    </div>
    <div class="p-4 bg-gradient-to-r from-primary to-primary-light">
      <h1 class="text-4xl text-white">{{ feed.name }}</h1>
    </div>
    <div class="px-4 py-3 bg-white flex flex-col rounded-b">
      <div class="flex" v-if="feed.message">
        <p class="text-sm mb-2">
          <span class="font-semibold text-sm mr-1">{{
            feed.user.username
          }}</span>
          {{ feed.message }}
        </p>
      </div>
      <span class="text-gray-800"
        ><strong>{{ feed.likes.length }}</strong> {{ $t("like-that") }}</span
      >
      <span class="text-xs text-gray-400 uppercase mt-1">{{
        getTimeDiff(feed.createdAt)
      }}</span>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "FeedItem",
  props: ["feed"],
  setup(props) {
    const getTimeDiff = (date) => window.dayjs(date).fromNow();
    const router = useRouter();

    const openDiary = () => {
      router.push(`/${props.feed.user.username}/${props.feed._id}`);
    };

    return {
      getTimeDiff,
      openDiary,
    };
  },
};
</script>